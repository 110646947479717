import React, { useState } from 'react';
import styled from '@emotion/styled';
import CancelOutlined from '@mui/icons-material/CancelOutlined';
import { useTranslation } from 'next-i18next';
import Backdrop from '@mui/material/Backdrop';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { getCdnUrl } from '@bladebinge/web-service-common/src/utils/get-cdn-url';

const StyledModalCancelIcon = styled(CancelOutlined)(({ theme }) => ({
    cursor: 'pointer',
    position: 'absolute',
    top: '6px',
    right: '6px',
    color: 'white',
    fontWeight: 'bold'
}));

const StyledFormLegalContentWrap = styled('div')(({ theme }) => ({
    position: 'absolute',
    backgroundColor: 'white',
    color: 'black',
    width: '75vw',
    maxWidth: '800px',
    maxHeight: '80vh',
    margin: '10vh auto',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '16px 32px',
    textAlign: 'center',
    overflowY: 'scroll'
}));

export const WhatIsBladebingeModal = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const toggleModal = () => setIsModalOpen((previousOpenState) => !previousOpenState);

    return (
        <>
            <Button
                fullWidth
                onClick={toggleModal}
                sx={{ position: 'relative', bottom: '4px' }}
                color="inherit"
                variant="text"
            >
                <Typography
                    variant="h6"
                    component="div"
                    sx={{
                        fontSize: {
                            xs: '1.20rem',
                            sm: '1.70rem',
                            md: '1.80rem',
                            lg: '1.90rem'
                        },
                        fontWeight: 'normal',
                        textAlign: 'center',
                        width: '100%'
                    }}
                >
                    {t('cms:what_is_bladebinge.so_what_is_bladebinge_no_punctuation')}
                    <HelpIcon sx={{ textColor: 'primary.contrastText', fontSize: '1.4rem', ml: 1 }} />
                </Typography>
            </Button>
            <Modal
                aria-label={t('common:listing_form.bladebinge_condition_guidelines')}
                disableEscapeKeyDown={false}
                open={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                closeAfterTransition
                slots={{
                    backdrop: Backdrop
                }}
                sx={{ overflow: 'scroll' }}
            >
                <>
                    <StyledModalCancelIcon onClick={() => setIsModalOpen(false)} />
                    <StyledFormLegalContentWrap>
                        <Typography variant="h4" component="div" sx={{ mt: 2 }}>
                            {t('cms:what_is_bladebinge.so_what_is_bladebinge')}
                        </Typography>
                        <Box sx={{ mt: 5, textAlign: 'center' }}>
                            <img
                                src={getCdnUrl('/logos/bladebinge_logo_300_116.png')}
                                width="200"
                                height="77"
                                alt={t('cms:what_is_bladebinge.bladebinge_logo_alt')}
                            />
                        </Box>
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {t('cms:what_is_bladebinge.bladebinge_paragraph')}
                        </Typography>
                        <Box sx={{ mt: 4, textAlign: 'center' }}>
                            <img
                                src={getCdnUrl('/logos/bb_shop_com_logo_300_78.png')}
                                width="200"
                                height="52"
                                alt={t('cms:what_is_bladebinge.bladebinge_shop_logo_alt')}
                            />
                        </Box>
                        <Typography variant="body1" sx={{ mt: 2 }}>
                            {t('cms:what_is_bladebinge.bladebinge_shop_paragraph')}
                        </Typography>
                    </StyledFormLegalContentWrap>
                </>
            </Modal>
        </>
    );
};
