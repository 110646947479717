import React from 'react';
import Link from 'next/link';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RepeatIcon from '@mui/icons-material/Repeat';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import StarIcon from '@mui/icons-material/Star';
import styled from '@emotion/styled';
import { useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { getMeFromLocalStorage } from '@bladebinge/web-service-common/src/utils/local-storage/local-storage-util';
import { bodyFont } from '@bladebinge/web-service-common/src/constants/fonts';
import { StyledCmsHeading } from './CmsHeading';
import { CmsSubheading } from './CmsSubheading';
import { WhatIsBladebingeModal } from './WhatIsBladebingeModal';

const UnstyledAnchor = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    color: 'inherit',
    display: 'block'
}));

const ICON_STYLES = {
    fontSize: {
        xs: '1.75em',
        sm: '2.40emem',
        md: '3.5em',
        lg: '4.25em'
    },
    my: {
        xs: 0.25,
        sm: 0.25,
        md: 0.5
    }
};

const HEADING_STYLES = {
    fontSize: {
        xs: '1.1em',
        sm: '1.5em',
        md: '1.80em',
        lg: '2.00em'
    }
};

const SUB_HEADING_STYLES = {
    display: {
        xs: 'none',
        sm: 'none',
        md: 'block'
    },
    fontFamily: bodyFont.style.fontFamily,
    fontWeight: 'normal',
    fontSize: '1.0em',
    lineHeight: '1em',
    textAlign: 'center',
    wordBreak: 'break-word',
    mb: 1.75,
    maxWidth: '80%',
    mx: 'auto'
};

export const BuySellRateRepeat = () => {
    const { t } = useTranslation();
    const { id: meId } = getMeFromLocalStorage() ?? {};
    const isSignedIn = Boolean(meId);
    const sellLinkUrl = isSignedIn ? `/users/${meId}/account?view=my_listings&formFlow=0` : '/get_started';

    return (
        <Grid container justifyContent="space-around" sx={{ my: 0, px: 0, py: 1 }}>
            <Grid item justifyContent="center" xs={3}>
                <UnstyledAnchor href="/browse">
                    <Box sx={{ textAlign: 'center' }}>
                        <ShoppingCartIcon sx={ICON_STYLES} />
                    </Box>
                    <StyledCmsHeading sx={HEADING_STYLES}>{t('cms:general.buy')}</StyledCmsHeading>
                    <CmsSubheading sx={SUB_HEADING_STYLES}>
                        {t('cms:headlines.find_the_best_deals_subheading')}
                    </CmsSubheading>
                </UnstyledAnchor>
            </Grid>
            <Grid item justifyContent="center" xs={3}>
                <UnstyledAnchor href={sellLinkUrl}>
                    <Box sx={{ textAlign: 'center' }}>
                        <MonetizationOnIcon sx={ICON_STYLES} />
                    </Box>
                    <StyledCmsHeading sx={HEADING_STYLES}>{t('cms:general.sell')}</StyledCmsHeading>
                    <CmsSubheading sx={SUB_HEADING_STYLES}>
                        {t('cms:headlines.sell_your_knife_quickly_subheading')}
                    </CmsSubheading>
                </UnstyledAnchor>
            </Grid>
            <Grid item justifyContent="center" xs={3}>
                <Box sx={{ textAlign: 'center' }}>
                    <StarIcon sx={ICON_STYLES} />
                </Box>
                <StyledCmsHeading sx={HEADING_STYLES}>{t('cms:general.rate')}</StyledCmsHeading>
                <CmsSubheading sx={SUB_HEADING_STYLES}>{t('cms:headlines.rate_to_help_others')}</CmsSubheading>
            </Grid>
            <Grid item justifyContent="center" xs={3}>
                <Box sx={{ textAlign: 'center' }}>
                    <RepeatIcon sx={ICON_STYLES} />
                </Box>
                <StyledCmsHeading sx={HEADING_STYLES}>{t('cms:general.repeat')}</StyledCmsHeading>
                <CmsSubheading sx={SUB_HEADING_STYLES}>{t('cms:headlines.growing_community_subheading')}</CmsSubheading>
            </Grid>
            <Grid item xs={12} sx={{ mt: 0 }}>
                <WhatIsBladebingeModal />
            </Grid>
        </Grid>
    );
};
